ul,
li {
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex-grow: 1;
}

.content__wrapper {
  padding: 40px;
  background-color: white;
}

.box {
  position: relative;
  padding: 33px 75px;
  margin-bottom: 17px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 10px #7a7a7a;
  box-shadow: 0 0 10px #7a7a7a;
  font-size: 22px;
  background-color: white;
}

.link {
  text-decoration: underline;
  color: #24476a !important;
}

.box h2 {
  margin-bottom: 50px;
  font-size: 28px;
}

@media only screen and (max-width: 840px) {
  .box h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 420px) {
  .box h2 {
    font-size: 18px;
  }
}

.signUpError {
  font-size: 16px;
  color: red;
  align-self: center;
  max-height: 90px;
  overflow: hidden;
}

.signUpConsent {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media only screen and (max-width: 420px) {
  .steps__info h5 {
    font-size: 18px;
  }
}

.person-image--container {
  position: relative;
  width: 100%;
}

.person-image--container .person-image--empty {
  width: 100%;
  height: 20vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.person-image--container .control {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 5px;
  left: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
  transition: 0.5s ease;
}

.person-image--container:hover .control {
  opacity: 1;
  visibility: visible;
}

.custom-file-input {
  color: transparent;
  width: 64px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: '+';
  text-align: center;
  display: inline-block;
  width: 64px;
  background: #1976d2;
  color: #fff;
  border-radius: 4px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 32px;
  transition: 0.5s ease;
}

.custom-file-input:hover::before {
  background-color: #1565c0;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.custom-file-input:active {
  outline: 0;
}

.signUpConsent input {
  width: 23px;
  height: 23px;
}

/*For new text field */
.customInput > div {
  background: white;
  width: 100%;
}

.customInput .MuiInputBase-input:disabled {
  background: #f2f9fe;
}

.customInput > div fieldset {
  border: 1px solid #24476a;
}

.customInput .MuiInputBase-input:disabled + fieldset {
  border: 1px solid #849cb3;
}

.customInput input {
  padding: 25px 30px;
  font-size: 22px;
}

.customInput textarea:disabled {
  background-color: #fff !important;
}

.datePickerInput > div {
  background: white;
  width: 100%;
}

.datePickerInput .MuiInputBase-input:disabled {
  background: #f2f9fe;
}

.datePickerInput > div fieldset {
  border: 1px solid #24476a;
}

.customInput .MuiInputBase-input:disabled + fieldset {
  border: 1px solid #849cb3;
}

.datePickerInput input {
  padding: 22px 0 22px 10px;
  font-size: 22px;
  min-width: 54px;
}

.MuiAutocomplete-input {
  padding: 25px 30px !important;
  font-size: 22px !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 !important;
}

.inputWithTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.inputWithTitle span {
  font-size: 24px;
  font-weight: bold;
  color: #24476a;
  text-transform: uppercase;
}

.requiredSymbol {
  color: #db4646;
  font-size: 16px;
  position: relative;
  top: -6px;
  left: 3px;
}

.inputWithTitle .requiredSymbol {
  color: #db4646;
  font-size: 16px;
  position: relative;
  top: -6px;
  left: 3px;
}

.customSvgIconDatePicker svg {
  width: 30px;
  height: 30px;
  color: #25476a !important;
}

/*multiline */
.MuiInputBase-multiline {
  padding: 30px !important;
  font-size: 22px !important;
}

/*radio */
.MuiRadio-root.Mui-checked {
  color: #26a3c5 !important;
}

/* switch  */
.MuiSwitch-switchBase.Mui-checked {
  color: #24a2c5 !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #24476a !important;
  opacity: 1 !important;
}

/*additional*/
.delimitierGrey {
  width: 100%;
  height: 1px;
  background: #a3a5a6;
}

/*in new create memorial we use this styles */
.defaultText {
  display: block;
  font-weight: 400;
  color: #24476a;
  line-height: 34px;
}

.bigTitle {
  font-size: 62px;
  font-weight: 700;
  color: #24476a;
}

.defaultTitle {
  font-size: 36px;
  color: #26a3c5;
  font-weight: 700;
}

.defaultTitle1 {
  font-size: 28px;
  font-weight: 700;
  color: #24476a;
}

.colorGrey {
  color: #333333 !important;
}

.colorNavy {
  color: #24476A !important;
}

/*custom select*/
.customSelect {
  height: max-content;
  background: white;
}

.customSelect .MuiSelect-select {
  font-size: 22px;
  display: flex;
  align-items: center;
}

.customSelect.Mui-disabled {
  background: #f2f9fe;
  border: 1px solid #849cb3;
}

.customSelect select:disabled {
  background: #f2f9fe;
}

.customSelect svg {
  color: #737373;
}

.customSelectMenu .MuiMenu-list {
  padding: 25px 20px !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #fafcfe;
  border-radius: 4px;
}

.customSelectMenu .MuiMenuItem-root {
  border-bottom: 1px solid #a3a5a6;
  font-size: 22px !important;
  padding: 0 0 15px 0;
}

.customSelectMenu .MuiMenuItem-root:last-child {
  border-bottom: none;
  padding: 0;
}

.customSelectMenu .MuiMenuItem-root:hover {
  background: none;
  color: #26a3c5;
}

.customSelectMenu .Mui-selected {
  background: none !important;
  color: #26a3c5;
}

.selectRows .MuiMenu-list {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center;
  width: 221px !important;
}

/*button*/
.customButton {
  font-size: 27px;
  line-height: 27px;
  border: 1px solid #26a3c5;
  background: white;
  font-weight: 400;
  color: #24476a;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 40px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.customButton:hover {
  background: rgba(0, 0, 0, 0.05);
}

/*image type*/
.imagePortrait {
  width: 433px;
  height: 100%;
  object-fit: cover;
}

.imageSquare {
  width: 433px;
  height: 433px;
  object-fit: cover;
}

.imageLandscape {
  width: 775px;
  height: 433px;
  object-fit: cover;
}

@media print {
  .imagePortrait {
    width: 333px;
    height: 100%;
    object-fit: cover;
  }

  .imageSquare {
    width: 333px;
    height: 333px;
    object-fit: cover;
  }

  .imageLandscape {
    width: 675px;
    height: 333px;
    object-fit: cover;
  }
}

.toast-body {
  font-size: 22px;
  padding: 20px 10px;
  color: #24476a;
  text-align: center;
  /* color: #fff; */
}

/* editor */
.editorBlock .tox-editor-header {
  cursor: move !important;
}

/* media  */
@media only screen and (max-width: 900px) {
  .customTable .MuiTableBody-root .MuiTableCell-root {
    padding: 8px !important;
  }
}

@media only screen and (max-width: 600px) {
  .customInput input {
    padding: 15px 20px !important;
    font-size: 18px !important;
  }

  .datePickerInput input {
    padding: 15px 10px;
    font-size: 18px;
  }

  .customInput textarea {
    font-size: 18px;
  }

  .inputWithTitle span {
    font-size: 18px;
  }

  .customButton {
    font-size: 16px;
    line-height: 16px;
    padding: 14px;
  }

  .bigTitle {
    font-size: 42px;
  }

  .defaultTitle {
    font-size: 26px;
  }

  .defaultTitle1 {
    font-size: 22px;
  }

  .customSelect .MuiSelect-select {
    font-size: 18px;
  }
}

.MuiCalendarPicker-root [role='presentation'],
.MuiCalendarPicker-root .MuiPickersArrowSwitcher-root {
  opacity: 0 !important;
  display: none !important;
}

.MuiCalendarPicker-root > :not(.PrivatePickersFadeTransitionGroup-root) {
  opacity: 0 !important;
  display: none;
}

.MuiCalendarPicker-root .PrivatePickersSlideTransition-root {
  min-height: 200px !important;
}
