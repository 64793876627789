.wrap {
  width: 100%;
  margin-top: 35px;
  flex: 1 0 auto;

  .editorBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;

    .editorBlock__itemLeft {
      justify-self: end;
    }

    .editorBlock__itemRight {
      justify-self: start;
    }
  }
}

.imageBorder {
  padding: 12px;
  background: #fff;
}

.blocks_mt {
  margin-top: 45px;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

.emptyMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 22px;
  color: #26476a;

  @media (max-width: 600px) {
    font-size: 16px;
    text-align: center;
  }
}

.headerImg {
  padding-bottom: 75px;

  @media (max-width: 1200px) {
    padding-bottom: 24px;
  }
}

.imgLine {
  height: 93px;
  width: 100%;
  border-bottom: 4px dashed white;
  padding-bottom: 15px;
}

.content {
  display: flex;
  gap: 50px;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}

.contentLeft {
  padding: 10px;
  background: #26476a;
  width: fit-content;
  flex-shrink: 0;
}

.contentLeftInside {
  width: 485px;
  border: 4px dashed #396b9f;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
}

.contentLeftInside .title {
  font-weight: 700;
  font-size: 32px;

  @media (max-width: 600px) {
    font-size: 24px;
  }
}

.contentLeftInside .text {
  font-size: 26px;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    font-size: 20px;
  }
}

.containerPart {
  width: 50%;
}

.contentRight {
  display: flex;
  gap: 50px;

  @media (max-width: 900px) {
    gap: 24px;
  }

  @media (max-width: 600px) {
    gap: 16px;
  }
}

.contentRightColumn1 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: fit-content;
  max-width: 465px;
}

.contentRightColumn2 {
  @extend .contentRightColumn1;
}

.contentItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contentItem .title {
  font-size: 22px;
  color: #26476a;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 18px;
  }
}

.contentItem .text {
  font-size: 18px;
  color: #333333;
  word-break: break-word;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.arrayType {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.textBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.textBlock .read {
  font-style: italic;
  text-decoration: underline;
  font-size: 18px;
  color: #26476a;
  cursor: pointer;
}

.button__block {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 50px;

  @media only screen and (max-width: 600px) {
    margin-bottom: 24px;
  }
}

.details__addPhoto {
  width: 465px;

  @media (max-width: 545px) {
    width: calc(100vw - 80px);
  }
}

.details__noImageText {
  text-align: center;
  font-size: 22px;
  width: 465px;
  height: 465px;
  background-color: #fff;
  color: #26476a;

  span {
    display: block;
    margin-top: 50%;
  }

  @media (max-width: 545px) {
    width: calc(100vw - 80px);
    height: auto;
    aspect-ratio: 1;
  }
}

.textBlock__content {
  font-size: 22px;

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
}

@media print {
  .button__block {
    display: none;
  }

  .details__addPhoto {
    width: 300px;
  }

  .content {
    flex-direction: row;
  }

  .read {
    display: none;
  }

  .containerPart {
    width: 100%;
  }

  .contentLeft,
  .contentRight {
    width: 50%;
  }

  .contentLeft {
    background-color: transparent;
  }

  .contentLeft .title {
    color: #26476a;
  }

  .contentLeft .text {
    color: #333333;
  }

  .imgLine {
    height: 55px;
  }

  .contentLeft,
  .contentRight {
    width: 50%;
  }

  .details__noImageText {
    width: 300px;
    height: 300px;
  }
}
