@import 'utilities/colors.scss';

.firstBlock {
  padding-bottom: 170px;
}

.textWithTopMargin {
  margin-top: 30px;
}

.startText {
  display: block;
  font-weight: 400;
  color: $color_dark_blue;
  line-height: 34px;
}

.remembering__title {
  font-size: 36px;
  color: #26a3c5;
  font-weight: 700;
  margin: 70px 0 32px;
}

.remembering {
  margin-top: 40px;
  display: flex;
  gap: 24px;
}

.name_block {
  display: flex;
  gap: 24px;
  width: 100%;
}

.date_block {
  display: flex;
  gap: 24px;
  width: 100%;
}

.date_box {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .date_box__title {
    font-size: 24px;
    color: $color_dark_blue;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.date_wrapper {
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 8px 10px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: -4px;
}

.details {
  margin-top: 70px;
}

.details__info {
  display: flex;
  flex-direction: column;
}

.details__createImage {
  margin-top: 50px;
}

.details__title {
  @extend .remembering__title;
  margin-top: 0;
}

.details__infoActions {
  margin-top: 40px;
  display: flex;
  gap: 60px;
}

.details__addPhoto {
  min-width: 465px;
  height: 505px;
  background: white;
  border: 1px solid $color_dark_blue;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details__addPhoto_title {
  font-size: 24px;
  font-weight: bold;
  color: $color_dark_blue;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.details__addPhoto img {
  display: block;
  min-width: 100%;
  max-width: 463px;
  height: 100%;
  object-fit: contain;
}

.details__addPhotoActions {
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  gap: 8px;
}

.details__addPhotoActionSvg {
  padding: 3px;
  background: #999999;
  display: flex;
  color: #fff;
  cursor: pointer;
}

.details__addPhotoActionSvg:hover {
  background: rgba(#999999, 0.8);
}

.details__addPhotoInside {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.details__addPhotoInside span {
  color: #999999;
}

.details__addPhotoInsideSvg {
  @extend .details__addPhotoActionSvg;
  padding: 11px;
  border-radius: 4px;
}

.details__inputs {
  display: flex;
  gap: 45px;
  width: 100%;
}

.details__columnInput {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}

.details__input {
  width: calc(100% - 46px);

  display: flex;
  flex-direction: column;
  gap: 25px;
}

.details__input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.details__input_title {
  margin: 0;
  font-size: 24px;
  color: $color_dark_blue;
  font-weight: 700;
  text-transform: uppercase;
}

.details__inputWrap {
  position: relative;
}

.details__inputSvg {
  @extend .details__addPhotoActionSvg;

  border-radius: 50%;
  padding: 6px;

  position: absolute;
  top: 0;
  transform: translateY(23px);
  right: -46px;
}

.tributes {
  margin-top: 70px;
}

.tributes__info {
  @extend .details__info;
  gap: 30px;
}

.tributes__title {
  font-size: 36px;
  color: #26a3c5;
  font-weight: 700;
  margin: 70px 0 0;
}

.tributes__radio {
  margin: 30px 0 50px;
}

.tributes__example {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.tributes__exampleTitle {
  font-size: 24px;
  color: $color_dark_blue;
  font-weight: 700;
}

.tributes__exampleInfo {
  display: flex;
  gap: 12px;
  padding: 20px 0;
  border-top: 1px solid #a3a5a6;
  border-bottom: 1px solid #a3a5a6;
  margin-bottom: 70px;
  width: max-content;

  img {
    width: 36px;
    height: 61px;
  }
}

.tributes__exampleText {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tributes__exampleText span:first-child {
  font-weight: 700;
}

.tributes__exampleText span:last-child {
  font-style: italic;
}

.actions {
  padding-top: 22px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;
}

.actions__left {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.actions__right {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
}

.actions__button {
  font-size: 27px;
  border: 1px solid #26a3c5;
  background: white;
  font-weight: 400;
}

.actions__buttonText {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mandatoryText {
  display: flex;
  gap: 12px;
  margin-top: 16px;

  p {
    font-size: 20px;
    color: $color_red;
  }
}

@media only screen and (max-width: 1600px) {
  .remembering {
    flex-direction: column;
  }

  .date_block {
    flex-direction: column;
  }

  .date_box {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1500px) {
  .details__infoActions {
    flex-direction: column;
    gap: 24px;
  }
}

@media only screen and (max-width: 1400px) {
  .actions {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .actions__right {
    align-self: flex-end;
  }
}

@media only screen and (max-width: 1200px) {
  .details__inputs {
    gap: 24px;
  }

  .remembering__title {
    margin-top: 48px;
  }

  .details__title {
    margin-top: 0;
  }

  .tributes__title {
    margin: 0;
  }

  .tributes__exampleInfo {
    width: auto;
    margin-bottom: 48px;
  }

  .example__line {
    display: none;
  }

  .actions {
    flex-direction: column;
    gap: 24px;
  }

  .actions__right {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .name_block {
    flex-direction: column;
    gap: 24px;
  }

  .date_block {
    gap: 24px;
  }

  .date_wrapper {
    flex-direction: column;
  }

  .details__inputs {
    gap: 24px;
    flex-direction: column;
  }

  .firstBlock {
    padding-bottom: 100px;
  }

  .tributes {
    margin-top: 24px;
  }

  .details {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .firstBlock {
    padding-bottom: 0;
  }

  .startText {
    font-size: 16px;
  }

  .remembering {
    margin-top: 20px;
  }

  .remembering__title {
    font-size: 24px;
    margin: 32px 0 16px;
  }

  .date_box {
    .date_box__title {
      font-size: 18px;
    }
  }

  .details__info {
    gap: 16px;
  }

  .details__infoActions {
    margin-top: 20px;
  }

  .details__addPhoto {
    min-width: 100%;
    height: 465px;
  }

  .details__addPhoto_title {
    font-size: 18px;
  }

  .details__inputSvg {
    transform: translateY(10px);
  }

  .details__input_title {
    font-size: 18px;
  }

  .tributes__radio {
    margin: 20px 0;
  }

  .tributes__example {
    gap: 16px;
  }

  .tributes__exampleInfo {
    font-size: 16px;
    margin-bottom: 0;
  }

  .actions__button {
    font-size: 16px;
  }

  .actions__left,
  .actions__right {
    gap: 12px;
  }

  .actions__buttonText {
    gap: 8px;

    span {
      font-size: 16px;
    }
  }

  .mandatoryText {
    p {
      margin-top: 3px;
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 400px) {
    .details__addPhoto {
      max-height: 380px;
    }
  }
}

@media print {
  .firstBlock {
    display: none;
  }
}
