.steps {
  display: flex;
  gap: 45px;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 150px;
    cursor: pointer;

    .stepCircle {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      border: 1px solid #999999;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      position: relative;
      padding: 4px;

      .stepCircle__inside {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #999999;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 28px;
      }

      .stepCircle__inside.active {
        background: #26a3c5;
      }
    }

    .stepCircle:after {
      position: absolute;
      display: block;
      content: '';
      width: 150px;
      height: 1px;
      background: #999999;
      left: 64px;
      top: 50%;
      transform: translateY(-50%);
    }

    .stepCircle__text {
      text-align: center;
    }
  }

  .step:last-child {
    .stepCircle::after {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .steps {
    flex-direction: column;
    gap: 115px;

    .step {
      .stepCircle:after {
        left: -18px;
        top: 190px;
        transform: rotate(90deg);
        width: 100px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .steps {
    gap: 90px;
  }
}
