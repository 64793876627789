.block {
  padding-bottom: 170px;
}

.title {
  font-size: 36px;
  color: #24476a;
  display: flex;
  gap: 8px;
}

.title span:first-child {
  font-weight: 700;
}

.addTextPhoto {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
}

.instruction {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
}

.instruction ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 22px;
}

.instruction ul li {
  list-style-type: decimal;
}

.isMobile {
  display: none;
}

.editor {
  position: relative;
  margin-top: 80px;
}

.editorBlock {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.editorBlock__content {
  display: flex;
  gap: 50px;
}

.editorBlock__imageRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

.editorBlock__imageRightNoCursor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

.editorBlock__imageRight img:hover,
.editorBlock__imageFull img:hover {
  cursor: move;
}

.editorBlock__image {
  @extend .editorBlock__imageRight;
  align-items: flex-end;
}

.editorBlock__imageNoCursor {
  @extend .editorBlock__imageRightNoCursor;
  align-items: flex-end;
}

.imgWrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.textWrapper {
  width: 100%;
  position: relative;
}

.editorBlock__imageFull {
  display: flex;
  justify-content: center;
}

.editorBlock__imageFullNoCursor {
  display: flex;
  justify-content: center;
}

.editorBlock .editorBlock__left,
.editorBlock__right {
  width: 50%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.editorBlock__add {
  height: 400px;
  width: 100% !important;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #24476a;
  background: white;
  gap: 40px;

  position: relative;
}

.actionButtons {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.details__addPhotoActionSvg {
  padding: 3px;
  background: #999999;
  display: flex;
  color: white;
  cursor: pointer;
}

.details__addPhotoActionSvg:hover {
  background: rgba(#999999, 0.8);
}

.editorBlock__addIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  cursor: pointer;

  color: #24476a;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;

  width: max-content !important;
}

.editorBlock__addIcon img {
  width: 40px;
  height: 40px;
}

.editorBlock__or {
  color: #24476a;
  font-size: 22px;
  font-weight: 700;
}

.editorBlock1 {
  border: 1px solid #24476a;
  background: white;
  width: 100%;
  height: 800px;

  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px;
  margin-top: 50px;
}

.editorBlock1__button {
  font-size: 20px;
  border: 1px solid #26a3c5;
  background: white;
  font-weight: 400;
  color: #24476a;
  width: fit-content;
  text-transform: uppercase;
  padding: 7px 16px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.editorBlock1__header {
  display: flex;
  justify-content: space-between;
}

.editorBlock1__headerLeft {
  display: flex;
  gap: 20px;
  align-items: center;
}

.editorBlock1__middle {
  display: flex;
  gap: 40px;
}

.editorBlock1__middleLeft {
  min-width: 600px;
  max-width: 600px;

  height: 600px;
  border: 1px solid #24476a;
  padding: 20px;
  border-radius: 4px;

  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  column-gap: 56px;
  row-gap: 28px;

  @media (max-width: 600px) {
    min-height: 188px;
    height: auto;
    column-gap: 20px;
    row-gap: 20px;
  }
}

.editorBlock1__middleLeft img {
  object-fit: cover;
  width: 250px;
  height: 250px;

  @media (max-width: 600px) {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 400px) {
    width: 100px;
    height: 100px;
  }
}

.editorBlock1__bottom {
  display: flex;
  justify-content: space-between;
}

.editorBlock1__bottomButtonLeft {
  width: 100%;
  max-width: 640px;
}

.editorBlock1__bottomButtonRight {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.editorBlock1__bottomButton,
.rotateAndSelectWrapper {
  display: flex;
  gap: 10px;
}

.editorBlock1__bottomButton_choice {
  display: flex;
  gap: 10px;
}

.editorBlock1__bottomSvg {
  margin: 0 !important;
  transform: scaleX(-1) rotate(-30deg);
}

.editorBlock2 {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 50px;
}

.editorBlock2__top {
  display: flex;
  gap: 50px;
}

.editorBlock2__top_wrapper {
  position: relative;
  width: 50%;
}

.editorBlock2 > div {
  opacity: 0.5;
}

.actions {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;
}

.actionIsContributor {
  justify-content: flex-end;
}

.actions__left {
  display: flex;
  gap: 24px;
  justify-content: space-between; 
}

.actionLeftIsContributor {
  flex-grow: inherit;
  width: auto;
}

.actions__right {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;

  @media only screen and (max-width: 1200px) {
    flex-direction: column; 
  }
}

.actions__button {
  font-size: 27px;
  border: 1px solid #26a3c5;
  background: white;
  font-weight: 400;
}

.actions__buttonText {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.blocks {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.createImageBlock {
  margin-top: 50px;
}

.createImageBlock.open {
  margin-bottom: 50px;
}

@media (max-width: 1400px) {
  .editorBlock1__bottomButtonLeft {
    max-width: 330px;
  }

  .editorBlock__add {
    flex-direction: column;
  }

  .editorBlock1__middleLeft {
    min-width: 292px;
    max-width: 292px;
  }

  .actions {
    flex-direction: column;
    align-items: center;
  }

  .actions__right {
    align-self: flex-end;
  }
}

@media (max-width: 1200px) {
  .editorBlock1 {
    height: auto;
  }

  .editorBlock1__bottomButtonLeft {
    max-width: 200px;
  }

  .editorBlock1__bottomButtonRight {
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    gap: 24px;
  }

  .editorBlock1__bottomButton {
    justify-content: flex-end;
  }

  .editorBlock1__bottomButton_choice {
    justify-content: flex-end;
  }

  .actions {
    flex-direction: column;
    gap: 24px;
  }

  .actions__right {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .isDesktop {
    display: none;
  }

  .isMobile {
    display: block;
  }

  .block {
    padding-bottom: 20px;
  }

  .blocks {
    padding: 0 8px;
  }

  .editorBlock2__top {
    flex-direction: column;
  }

  .editorBlock2__top_wrapper {
    width: 100%;
  }

  .editorBlock1 {
    padding: 20px;
    gap: 20px;
  }

  .editorBlock1__bottomButton {
    justify-content: space-between;
  }

  .editorBlock1__bottomButton_choice {
    justify-content: space-between;
  }

  .editorBlock1__middle {
    flex-direction: column;
    gap: 24px;
  }

  .editorBlock1__middleLeft {
    min-width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 30px;
  }

  .blocks {
    padding: 0;
    gap: 24px;
  }

  .addTextPhoto {
    gap: 8px;
    margin-top: 32px;
  }

  .instruction {
    gap: 8px;
    margin-top: 32px;
  }

  .editor {
    margin: 32px 0 0;
  }

  .editorBlock1 {
    padding: 16px;
  }

  .editorBlock1__middleLeft {
    padding: 16px;
  }

  .editorBlock2 {
    gap: 24px;
  }

  .editorBlock2__top {
    gap: 24px;
  }

  .column_reverse {
    flex-direction: column-reverse;
    gap: 24px;
  }

  .rotateAndSelectWrapper {
    justify-content: space-between;
  }

  .actions {
    margin-top: 24px;
  }

  .actions__button {
    font-size: 16px;
  }

  .actions__left,
  .actions__right {
    gap: 12px;
  }

  .actions__buttonText {
    gap: 8px;

    span {
      font-size: 16px;
    }
  }
}

@media print {
  .actions {
    display: none;
  }

  .block {
    display: none;
  }

  .editorBlock2__top {
    flex-direction: column;
    align-items: center;
  }
}
