@import 'utilities/colors.scss';

.link__block {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 70px;

  .link__item {
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    margin: 0 0.5rem 4rem;
    transition: all 0.3s;
    width: fit-content;

    &:hover {
      opacity: 0.7;
      transition: all 0.3s;
    }

    .item__text {
      font-size: 1.5rem;
      color: $color_blue;
    }
  }
}

.committee__block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  .committee__name {
    font-weight: 700;
  }
}

.list_item {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;

  .list_dot {
    height: 10px;
    width: 10px;
    background-color: $color_blue;
    border-radius: 50%;
    margin: 0 1rem 0 3rem;
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .link__block {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 35px;

    .link__item {
      margin: 0 0.5rem 2rem;
    }
  }
}

@media only screen and (max-width: 900px) {
  .committee__block {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .link__block {
    grid-template-columns: 1fr;
  }

  .committee__block {
    grid-template-columns: 1fr;
  }

  .list_item {
    .list_dot {
      margin: 0 1rem 0;
    }
  }
}
