.instruction {
  padding: 25px;
  background: white;
  width: 330px;
  border-radius: 4px;
  color: #509bbe;
  font-size: 22px;
  text-align: center;
  position: relative;
  line-height: 32px;
  cursor: pointer;
}

.instruction:hover {
  opacity: 0.7;
}

.instruction .instructionImg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.title {
  color: #2f6788;
  font-size: 72px;
  font-weight: 200;
  font-style: italic;
}

.text {
  font-size: 30px;
  color: #326fb5;
  line-height: 40px;
}

.text div {
  padding-top: 12px;
}

.textBottom {
  color: #549170;
  line-height: 40px;
  font-style: italic;
}

@media only screen and (max-width: 1350px) {
  .textBottom {
    padding-top: 50px;
    text-align: center;
  }

  .title {
    font-size: 50px;
  }

  .text {
    font-size: 26px;
  }
}
