@import 'utilities/colors.scss';

.firstStickyColumnCell {
  left: 0;
}

.secondStickyColumnCell {
  left: 250px;
}

.firstStickyColumnCell,
.secondStickyColumnCell {
  position: sticky;
  min-width: 250px;
  background-color: #e2f4fb;
  z-index: 1000;

  @media only screen and (max-width: 700px) {
    position: unset;
  }
}

.makeAdmin_box {
  display: flex;
  align-items: center;
  gap: 48px;
  margin: 0 0 48px;
}

.showQuilts_box {
  display: flex;
  gap: 24px;
  margin: 48px 0 0;
}

.resetBtn {
  font-size: 24px;
  font-weight: 700;
  color: $color_dark_blue;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
}

.adminBtn {
  width: fit-content;

  &:disabled {
    color: $color_grey;

    &:hover {
      background-color: #fff;
      cursor: auto;
    }
  }
}
